<template>
  <div
    slot="reference"
    class="me-image-mark-point"
    @click="clickPoint"
    v-drag
    :style="{
      left: `${coordinate.x}px`,
      top: `${coordinate.y}px`,
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <slot
      name="icon"
      :detail="point"
    >
      <!-- 图标 -->
      <img :src="icon" />
    </slot>
  </div>
</template>

<script>
import Bus from "./bus"
export default {
  directives: {
    drag: {
      // 指令的定义
      bind: function (el, binding, vnode) {
        let odiv = el //获取当前元素
        const _this = vnode.context
        //非采集模式不能拖动
        if (!_this.gather) return
        el.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft
          let disY = e.clientY - odiv.offsetTop
          let left = ""
          let top = ""
          document.onmousemove = (e) => {
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            left = e.clientX - disX
            top = e.clientY - disY
            //绑定元素位置到positionX和positionY上面
            //移动当前元素
            if (left <= 0) left = _this.size / 2
            if (top <= 0) top = _this.size / 2
            if (left + _this.size >= _this.imgWidth) left = _this.imgWidth - _this.size / 2
            if (top + _this.size >= _this.imgHeight) top = _this.imgHeight - _this.size / 2
            odiv.style.left = left + "px"
            odiv.style.top = top + "px"
            console.log(`x:${left},y:${top}`)
          }
          document.onmouseup = (e) => {
            const left = odiv.style.left.replace("px", "")
            const top = odiv.style.top.replace("px", "")
            const point = JSON.parse(JSON.stringify(_this.point))
            point.x = Number(left)
            point.y = Number(top)
            console.log(`end x:${point.x},y:${point.y}`)
            _this.$emit("update", point, _this.index)
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      },
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    icon() {
      if (this.visible) return require("./icons/icon_sxdw_select.png")
      return require("./icons/icon_sxdw.png")
    },
    coordinate() {
      let x = Number(this.point.x)
      let y = Number(this.point.y)
      if (x - this.size / 2 < 0) x = this.size / 2
      if (x + this.size / 2 > this.imgWidth) x = this.imgWidth - this.size / 2
      if (y - this.size / 2 < 0) y = this.size / 2
      if (y + this.size / 2 > this.imgHeight) y = this.imgHeight - this.size / 2
      return {
        x: x * this.basis,
        y: y * this.basis,
      }
    },
  },
  props: {
    point: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //缩放比例
    basis: Number,
    //左边点下标
    index: Number,
    //坐标基准宽高
    size: {
      type: Number,
      default: 60,
    },
    // 是否为采集模式
    gather: {
      type: Boolean,
      default: false,
    },
    //初始化后图片宽度
    imgWidth: Number,
    //初始化后图片高度
    imgHeight: Number,
  },
  created() {
    Bus.on("show-popover", this.closePopover)
  },
  methods: {
    clickPoint() {
      if (!this.gather) {
        this.visible = !this.visible
        if (this.visible) {
          Bus.$emit("show-popover", this.point)
        }
      }
      this.$emit("clickPoint", this.index, this.point)
    },
    closePopover(val = {}) {
      console.log("接受到关闭", val)
      if (val?.x == this.point.x && val?.y == this.point.y) {
        return
      }
      this.visible = false
    },
  },
  beforeDestroy() {
    Bus.$off("show-popover", this.closePopover)
  },
}
</script>

<style lang="less">
.me-image-mark-point {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  & > img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  // &-content {
  //   display: flex;
  //   flex-direction: column;
  //   &-address {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     & > img:nth-child(1) {
  //       width: 12px;
  //       height: 14px;
  //     }
  //     & > div:nth-child(2) {
  //       font-weight: 400;
  //       font-size: 14px;
  //       color: #ffffff;
  //       line-height: 20px;
  //       margin-left: 6px;
  //     }
  //   }
  //   &-title {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-between;
  //     align-items: center;
  //     &-label {
  //       display: flex;
  //       flex-direction: row;
  //       align-items: center;
  //       & > div:nth-child(1) {
  //         font-weight: 500;
  //         font-size: 14px;
  //         color: #66bbf9;
  //         line-height: 20px;
  //       }
  //       & > div:nth-child(2) {
  //         font-size: 12px;
  //         color: #66bbf9;
  //         line-height: 17px;
  //         margin-left: 10px;
  //       }
  //     }
  //   }
  // }
}
.me-image-mark-point:hover {
  z-index: 999;
}
.me-image-mark-point:hover > .me-image-mark-point-child {
  opacity: 1;
}
/* 使元素置中 */
.me-image-mark-point-child {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
}
</style>
